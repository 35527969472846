<template lang="pug">
  v-container
    v-row
      v-col(
        v-for="client in clients"
        :key="client.id"
        cols="12"
        xl="1"
        lg="2"
        md="3"
        sm="6"
        xs="6"
        :class="client.thumb != null ? 'd-block' : 'd-none'"
      )
        v-card.pa-2.elevation-0
          v-img.clientRef( contain v-if="client.thumb != null " :src="/https?/i.test(client.thumb) ? client.thumb : `${mediasUrl}/${client.thumb}`" )
          v-icon.clientRef.clientRef__noImage.d-flex.justify-center.align-center( v-else large ) mdi-image-broken
</template>

<script>
import GettersService from '@/service/getters.service.js';
  export default {
    data: () => ({
      mediasUrl: 'http://medias.limbasse.com/images/references',
      clients: [],
    }),
    methods: {
      getClients: async () => {
        const get = new GettersService();
        let clients = await get.clients();
        console.log(clients);
        return clients?.data;
      },
      sortedClient(clients) {
        return clients.sort((clientA, clientB) => {
          if (clientA?.name && clientB?.name)
            return clientA.name.localeCompare(clientB.name);
        })
      }
    },
    async mounted(){
      this.clients = await this.getClients()
      this.clients = this.sortedClient(this.clients)
    }
  }
</script>

<style lang="scss" scoped>
  .clientRef{
    height: 75px;
  }
</style>