<template lang="pug">
  v-app-bar.elevation-0( app height="max-content" )
    v-list.d-flex.justify-center( width="100%" )
      v-list-item
        v-list-item-content
          v-list-item-title 
            router-link( :to="{ name: 'Portfolio'}" ) Portfolio
      v-list-item
        v-list-item-content
          v-list-item-title 
            router-link( :to="{ name: 'References'}") References
      v-list-item.logo
        v-list-item-content.pa-0
          v-img( contain width="0"  height="100px" src="@/assets/img/logo_careva.png" )
      v-list-item
        v-list-item-content
          v-list-item-title 
            router-link( :to="{ name: 'Discours'}" ) Discours
      v-list-item
        v-list-item-content
          v-list-item-title 
              router-link( :to="{ name: 'Contact'}" ) Contact
    p 
      strong CAREVA
      span  – Société spécialisée en 
      strong C
      span onception 
      strong AR
      span chitecturale 
      strong EV
      span ènementielle 
      strong A
      span ppliquée.
    v-divider.my-2.mx-auto.black.mainDivider( width="60%" )
</template>

<script>

export default {
  name: 'Nav',
  data: () => ({ }),
}
</script>

<style lang="scss">
  .v-toolbar {
    .v-toolbar__content{
      flex-direction: column;
      .v-list {
        padding: 5px 15%;
      } 
    }
  }
</style>