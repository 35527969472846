<template lang="pug">
  v-container
    v-row
      v-col( cols="12" )
        v-card.pa-5.themeLigthPerso( outline )
          v-row
            v-col.pa-0( cols="12" )
              v-card.resetVCard__tranparent.elevation-0( outline )
                v-row
                  v-col( cols="9" )
                    v-card-title.font-weight-bold CAREVA
                    v-card-subtitle.font-weight-bold.text-left  Careva est une société de création spécialisée en espace de communication événementielle. 
                    v-card-text Nous accompagnons nos clients et répondons à leur attentes sur leurs stratégies de communication en leur proposant des solutions sur-mesure, de conception et de mise en œuvre de leurs besoins. Careva se fait force de répondre à toutes les contraintes budgétaires et graphiques de ses clients. Careva a pour vocation de mettre en images et en plans vos besoins, afin de passer à la fabrication, au montage, à la gestion et au démontage de votre événement. Careva travaille avec un grand nombre de prestataires pour répondre aux grosses comme aux petites manifestations. 
                  v-col.pt-7( cols="3" )
                    v-img( contain src="@/assets/img/logo_careva.png" height="200px" )
          v-row
            v-col.pa-0( cols="12" )
              v-card.resetVCard__tranparent.elevation-0( outline )
                v-card-title.font-weight-bold STAND
                v-card-text Présent sur les salons depuis plus de 20 ans nous répondons à tous vos besoins. Nous maîtrisons les contraintes logistiques, de sécurités et de transports. Attentifs à nos clients, nous nous efforçons de livrer un projet de qualité en accord avec les contraintes de chacun. A la suite d'une étude détaillée de vos besoins, nous vous proposerons un projet en 3D et des plans. A votre écoute, nous adapterons notre projet en fonction de vos souhaits. Un accompagnement total ; gestion de la coordination des différents intervenants sur toute la durée du montage.
          v-row
            v-col.pa-0( cols="12" )
              v-card.resetVCard__tranparent.elevation-0( outline )
                v-card-title.font-weight-bold EVENEMENTIEL
                v-card-text CAREVA crée toutes soirées événementielles, tournées, roadshow, installations en GD ou en CHR. Soirées de lancement de produit, soirées d'exception, défilés de mode, conférences de presse, assemblées générales sociétaires. Nous trouvons pour vous un lieu, un thème et une ambiance adaptée à votre manifestation. Nous créeons une mise en scène festive, une décoration personnalisée et une prestation artistique à la hauteur de votre événement. Nous gérons toute la logistique qui en découle en respectant vos contraintes budgétaires pour faire de votre événement un moment inoubliable. 
</template>

<script>
  export default {
    data: () => ({}),
    methods: {},
    mounted(){}
  }
</script>

<style lang="scss" scoped>
  .theme--dark.scketchfabCard{
    border: none !important;
    background-color: transparent !important;
  }

  .v-card .v-card__text{
    border: none;
    background-color: transparent;
    height: max-content;
  }
</style>