<template lang="pug">
  v-app#app
    NavBar.d-none.d-md-block
    NavBarResponsive.d-md-none
    div.wrapperScrollable(
      :class="$vuetify.breakpoint.md || $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'responsive' : ''"
    )
      v-main.mt-6.mx-auto.pa-0
        router-view
    v-footer( app )
    
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import NavBarResponsive from '@/components/NavBarResponsive.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    NavBarResponsive
  },
  data: () => ({ }),
  methods: { },
  created () {
    document.title = "Infographie 3D – CAREVA, création d’espaces évènementiels. | Careva-Event";
  }
}
</script>

<style lang="scss">
@import url('./assets/fonts/Quicksand/stylesheet.css');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  background-image: url("./assets/img/background_3D-02.jpg"); 
  background-size: cover;
  background-attachment: fixed;
  position:relative;
	&:before {
		content:'';
		top:0;
		left:0;
		bottom:0;
		right:0;
		position:fixed;
		background-size: cover;
		animation:bgfade 10s infinite;
		animation-direction: alternate-reverse;
		opacity:0;
		background-image: url("./assets/img/background_3D-03.jpg");
  }

  * { 
    font-family: Quicksand !important; 
    color: #000 !important
  }

  .themeLigthPerso{
    border: 1px solid #000;
    background-color: rgba(255, 255, 255, .5) ;
  }

  .resetVCard__tranparent{
    border: none !important;
    background-color: transparent !important;
  }

  .wrapperScrollable{
    &.responsive{
      height: calc(100vh - 230px);
      top: 230px;
    }
    width: 100%;
    height: calc(100vh - 180px);
    position: fixed;
    top: 180px;
    overflow-y: scroll;
  }

  a{
    font-size: 20px;
    text-decoration: none;
    &.router-link-active:not(.allAction){ text-decoration: underline; } 
    &.router-link-exact-active{ text-decoration: underline; }
  }

  .v-app-bar,
  .v-list,
  .v-footer{
    background-color: transparent;
  }
}

@keyframes bgfade {
  0%  {opacity:0;}
  100%  {opacity:1;}
}
</style>
