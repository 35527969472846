<template lang="pug">
  v-container
    v-row
      v-col( cols="12" xs="3" sm="3" md="5" lg="5" xl="5")
        v-card.d-none.d-md-block.elevation-0( outline dark )
          iframe( 
            src="https://sketchfab.com/models/f9e8382eb9dc4d5a964ebf7836f5313d/embed?autostart=1"
            allowvr=""
            allowfullscreen=""
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            onmousewheel=""
            width="300"
            height="560"
            frameborder="0"
          )
      v-col( cols="12" xs="9" sm="9" md="7" lg="7" xl="7" )
        v-card.elevation-0.resetVCard__tranparent( outline )
          v-card-title.font-weight-bold.text-lg-h4 CAREVA
          v-card-subtitle.font-weight-bold.text-left.text-lg-h6 Société de Conception ARchitecturale EVenementielle Appliquée. 
          v-card-text.text-left.text-lg-body-1.ml-10.mb-7.pa-4.rounded 3 rue Madame de Sanzillon - 92110 CLICHY 
            br 
            | ~ 06.88.39.38.73 ~
            br 
            | jonathan@limbasse.com 
          v-card-actions
            a.mr-10( href="http://www.limbasse.com/" target="_blank" ) 
              v-img( src="@/assets/img/logo_nedjo.png" width="300px" )

</template>

<script>
  export default {
    data: () => ({}),
    methods: {},
    mounted(){}
  }
</script>

<style lang="scss" scoped>
  .v-card .v-card__text{
    border: none;
    background-color: transparent;
    height: max-content;
    border-left: 1px solid #000 !important;
  }

  .theme--dark.v-card,
  .theme--dark.v-image{
    border: none !important;
    background-color: transparent !important;
  }
  
</style>