<template lang="pug">
  div
    v-card.themeLigthPerso( outlined tile )  
      v-img.d-none.d-sm-block(
        :src="`${mediasUrl}/${project.medias.thumb}`"
        height="max-width"  
        @click.stop="dialog = !dialog"
      )
      v-img.d-sm-none(
        :src="`${mediasUrl}/${project.medias.thumb}`"
        height="max-width"
      )
      v-card-text.pt-2.d-flex.flex-column.align-center
        router-link.ma-0.text-md-body-1.text-uppercase.font-weight-bold( v-if="project.client" :to="{name: 'ProjectBy', params: { tagName: 'client', tag: project.client.name }}")  {{ `${project.client.name}` }}
        router-link.ma-0.text-md-body-2.font-weight-bold( v-if="project.year" :to="{name: 'ProjectBy', params: { tagName: 'year', tag: project.year }}" ) {{ `${project.year}` }}
        v-divider.my-1.black
        router-link.ma-0.text-md-body-2( v-if="project.projectType" :to="{name: 'ProjectBy', params: { tagName: 'projectType', tag: project.projectType.name }}" ) {{ `${project.projectType.name}` }}
        router-link.ma-0.text-md-body-2( v-if="project.eventType" :to="{name: 'ProjectBy', params: { tagName: 'eventType', tag: project.eventType.name }}" ) {{ `${project.eventType.name}` }}
        router-link.ma-0.text-md-body-2( v-if="project.event" :to="{name: 'ProjectBy', params: { tagName: 'event', tag: project.event.name }}" ) {{ `${project.event.name}` }}
        router-link.ma-0.text-md-body-2( v-if="project.color" :to="{name: 'ProjectBy', params: { tagName: 'color', tag: project.color.name }}" ) {{ `${project.color.name}` }}
    v-dialog( v-model="dialog" )
      v-card.pa-2( outlined tile )
        v-card-text.font-weight-bold.pb-1.text-align-left {{ `| ${project.client && project.client.name ? project.client.name + ' |' : ''} ${project.year ? project.year + ' |' : ''} ${project.projectType && project.projectType.name ? project.projectType.name + ' |' : ''} ${project.eventType && project.eventType.name ? project.eventType.name + ' |' : ''} ${project.event && project.event.name ? project.event.name + ' |' : ''} ${project.color && project.color.name ? project.color.name + ' |' : ''}` }}
        v-carousel( height="84.3vh" )
          v-carousel-item(
            v-for="image in project.medias.large"
            :key="project.id"
            :src="`${mediasUrl}/${image}`"
            reverse-transition="fade-transition"
            transition="fade-transition"
          )
</template>

<script>
  export default {
    data: () => ({
      mediasUrl: 'http://medias.limbasse.com/images/projects',
      dialog: false
    }),
    props: {
      project: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss">
  .v-dialog .theme--dark,
  .theme--dark.v-overlay{
    border: none !important;
    background-color: transparent !important;
  }
  .v-dialog {
    .v-card {
      .v-card__text {
        height: max-content;
        background-color: transparent;
        border: none;
      }
    }
  }
  .v-card {
    .v-card__text {
      height: 170px;
      // background-color: rgba(0,0,0,0.5);
      border-top: 1px solid #000;
      p { font-size: 12px; }
      hr { width: 90%;}
    }
  }
</style>