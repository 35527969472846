import Vue from 'vue';
import VueRouter from 'vue-router';

import Portfolio from '@/views/Portfolio';
import References from '@/views/References';
import Discours from '@/views/Discours';
import Contact from '@/views/Contact';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Portfolio'
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
    children: [
      {
        path: ':tagName/:tag',
        name: 'ProjectBy',
        component: Portfolio,
      }

    ]
  },
  {
    path: '/references',
    name: 'References',
    component: References,
  },
  {
    path: '/discours',
    name: 'Discours',
    component: Discours,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
