class BaseService {
  #baseURL = 'https://careva-api.herokuapp.com';

  #baseHeader = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  requestOptions(url) {
    const options = {};
    options.baseURL = this.#baseURL;
    options.headers = this.#baseHeader;
    options.responseType = 'json';
    options.url = url;
    options.method = 'GET';
    return options;
  }
}

export default BaseService;
