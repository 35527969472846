<template lang="pug">
  v-container
    v-row
      v-col( cols="8" )
      v-col.d-flex.justify-end.align-center( cols="4" )
        router-link.allAction( :to="{name: 'Portfolio'}" ) Afficher tout

    v-row
      v-col(
        v-for="(project, n) in projectsFiltered"
        :key="n"
        cols="12"
        xl="3"
        lg="4"
        md="6"
        sm="12"
        xs="12"
      )
        ProjectCard( 
          :project="project"
        )
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue';
import GettersService from '@/service/getters.service.js';
  export default {
    data: () => ({
      projects: [],
      projectsFiltered: [],
    }),
    components: {
      ProjectCard
    },
    methods: {
      getProjects: async () => {
        const get = new GettersService();
        let projects = await get.getBy('sites', 2);
        projects = projects?.map((p) => {
          let project = p
          project.medias = JSON.parse(project.medias[0])
          return project
        })
        return projects;
      },
      filteredProject (projects, to) {
        const tagName = to?.params?.tagName
        const tag = to?.params?.tag
        if ( projects && tagName && tag ){
          const result = projects?.filter((project) => {
            if (project[tagName]?.name == tag) return true;
            else if (project[tagName] == tag) return true;
            return false
          })
          return result
        }
        console.log(projects);
        return projects
      },
      sortedProject(projects, tagName) {
        return projects.sort((projectA, projectB) => {
          if (projectA[tagName]?.name && projectB[tagName]?.name)
            return projectA[tagName].name.localeCompare(projectB[tagName].name);
          else if (tagName === 'year')
            return projectB.year - projectA.year;
        })
      },
      sortedProjectInVue(projects, tagName) {
        this.filteredProject = this.sortedProject(projects, tagName)
      }
    },
    beforeRouteEnter(to, from, next) {
      next(async vm => {
        vm.projects = await vm.getProjects()
        console.log(to)
        if (to.query.sortBy) vm.projects = vm.sortedProject(vm.projects, to.query.sortBy)
        else vm.projects = vm.sortedProject(vm.projects, 'year')
        
        vm.projectsFiltered = vm.filteredProject(vm.projects, to)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.projectsFiltered = this.filteredProject(this.projects, to)
      if (to.query.sortBy) this.projectsFiltered = this.sortedProject(this.projectsFiltered, to.query.sortBy)
      else this.projectsFiltered = this.sortedProject(this.projectsFiltered, 'year')
      next()
    }
  }
</script>

<style lang="scss" scoped>
</style>