import axios from 'axios';
import BaseService from './base.service';

class GettersService extends BaseService {
  projects() { return axios(super.requestOptions('/projects')); }
  project(id) { return axios(super.requestOptions(`/projects/${id}`)); }

  clients() { return axios(super.requestOptions('/clients')); }
  client(id) { return axios(super.requestOptions(`/clients/${id}`)); }

  events() { return axios(super.requestOptions('/events')); }
  event(id) { return axios(super.requestOptions(`/events/${id}`)); }

  eventTypes() { return axios(super.requestOptions('/event_types')); }
  eventType(id) { return axios(super.requestOptions(`/event_types/${id}`)); }

  projectTypes() { return axios(super.requestOptions('/project_types')); }
  projectType(id) { return axios(super.requestOptions(`/project_types/${id}`)); }

  colors() { return axios(super.requestOptions('/colors')); }
  color(id) { return axios(super.requestOptions(`/colors/${id}`)); }

  users() { return axios(super.requestOptions('/users')); }
  user(id) { return axios(super.requestOptions(`/users/${id}`)); }

  async getBy(filter, id) {
    const entity = await axios(super.requestOptions(`/${filter}/${id}`));
    if (entity?.data?.projects) return entity.data.projects;
    return null;
  }
}

export default GettersService;
